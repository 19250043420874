<template>
  <section id="ads-edit">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t("adsDisplay.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("adsDisplay.adsEdit") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <div>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDatos"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("code.data") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('adsInfo') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col class="ml-2 mb-2">
                      <b-form @submit.prevent="updateInfo">
                        <!-- Title -->
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.name') + ' *'">
                              <b-form-input
                                v-model="name"
                                autocomplete="new-password"
                                :placeholder="$t('dataGeneric.name')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('code.activeAds')">
                              <b-form-checkbox
                                v-model="isActive"
                                switch
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.description')">
                              <b-form-textarea
                                id="textarea"
                                v-model="description"
                                :placeholder="$t('dataGeneric.description')"
                                rows="3"
                                max-rows="6"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-col style="text-align: end;">
                          <b-button
                            type="submit"
                            variant="success"
                          >
                            {{
                              $t("dataGeneric.save")
                            }}
                          </b-button>
                        </b-col>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>{{ $t('Configuration') }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDatos"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("adsDisplay.adsConfig") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('adsDisplay.adsConfig-description') }}
                  </h6>
                </b-card-header>
                <b-card-body :before-change="validationFormConfig">
                  <validation-observer
                    ref="ConfigRules"
                    tag="form"
                  >
                    <b-form @submit.prevent="updateConfig">
                      <b-row>
                        <b-col md="6">
                          <validation-provider
                            v-slot="validationContext"
                            name="adUnitId"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('adsDisplay.adsUnitId') + ' *'"
                              :invalid-feedback="$t('required')"
                              :state="validationContext.errors.length > 0 ? false : null"
                            >
                              <b-form-input
                                id="adUnitId"
                                v-model="adUnitId"
                                autocomplete="new-password"
                                :state="validationContext.errors.length > 0 ? false : null"
                                :placeholder="$t('adsDisplay.adsUnitId')"
                              />
                              <small class="text-primary">{{ $t("adsDisplay.adsUnitId-description") }}</small> <br>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('adsDisplay.adsLocation')">
                            <validation-provider
                              v-slot="validationContext"
                              name="location"
                              rules="required"
                            >
                              <b-form-select
                                v-model="location"
                                :disabled="true"
                                :options="locationOptions"
                              />
                              <small class="text-primary">{{ $t("adsDisplay.adsLocation-description") }}</small><br>
                              <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('adsDisplay.adsPosition')">
                            <validation-provider
                              v-slot="{ errors }"
                              name="position"
                              rules="required"
                            >
                              <div class="range-slider-container">
                                <label class="d-flex align-items-center">
                                  <b-form-input
                                    v-model="position"
                                    class="mr-1"
                                    type="number"
                                    min="0"
                                    :max="totalRootCategoriesCount"
                                  />
                                </label>
                                <b-form-input
                                  v-model="position"
                                  type="range"
                                  min="0"
                                  :max="totalRootCategoriesCount"
                                  class="custom-range range-input"
                                />
                                <div class="range-labels">
                                  <span>0</span>
                                  <span> {{ totalRootCategoriesCount }} </span>
                                </div>
                              </div>
                              <small class="text-primary">
                                {{ $t("adsDisplay.adsLocation-description") }}</small><br>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-col style="text-align: end;">
                        <b-button
                          :disabled="adUnitId === ''"
                          type="submit"
                          variant="success"
                        >
                          {{
                            $t("dataGeneric.save")
                          }}
                        </b-button>
                      </b-col>
                    </b-form>
                  </validation-observer>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import {
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, inputDecimalNumber } from '@/store/functions'

import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputDecimalNumber,
      video: null,
      ads: null,
      name: null,
      description: null,
      isActive: null,
      showDatos: false,
      userData: getUserData(),
      locationOptions: [
        { value: 'TOP', text: this.$t('top') },
        { value: 'MID', text: this.$t('middle') },
        { value: 'BOT', text: this.$t('bottom') },
      ],
      adUnitId: '',
      location: 'MID',
      position: 0,
      totalRootCategoriesCount: 10,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
           query{
            allDisplayAds(id:"${id}",client:"${this.userData.profile.client.id}"){
              edges {
                node {
                  id
                  name
                  description
                  location
                  position
                  adUnitId                  
                  campaign{
                    name
                  }
                  isActive                  
                }
              }
            }              
          }          
        `,
        })
        .then(result => {
          messageError(result, this)

          try {
            this.ads = result.data.data.allDisplayAds.edges[0].node

            this.name = this.ads.name
            this.description = this.ads.description
            this.location = this.ads.location
            this.position = this.ads.position
            this.adUnitId = this.ads.adUnitId
            this.displayCampaign = this.ads.displayCampaign

            this.isActive = this.ads.isActive
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => { })
    },
    routeToEditView() {
      window.history.back()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    updateInfo() {
      const { id } = this.$route.params
      this.showDatos = true

      axios
        .post('', {
          query: `
          mutation{
            updateDisplayAds(id:"${id}",input:{
              name:"${this.name}",            
              description:"${this.description}",
              isActive:${this.isActive},
            }){
              displayAd
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showDatos = false
        })
    },
    updateConfig() {
      const { id } = this.$route.params
      this.showDatos = true

      axios
        .post('', {
          query: `
          mutation{
            updateDisplayAds(id:"${id}",input:{
              location:${this.location},              
              position:${this.position},
              adUnitId:"${this.adUnitId}",
            }){
              displayAd
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showDatos = false
        })
    },
    validationFormConfig() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfigRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getRootCategories() {
      axios
        .post('', {
          query: `
            query{  
            allCategories(
              parent_Isnull: true
              state: "GRE"
              client:"${this.userData.profile.client.id}",
              ){
              totalCount
              edgeCount
              edges{
                node{
                  id
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.totalRootCategoriesCount = res.data.data.allCategories.totalCount
        }).catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#ads-edit .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#ads-edit .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#ads-edit .card-body h4 {
  font-size: 1.286rem !important;
}

#ads-edit .tamanio {
  width: 45vw;
  height: 30vw;
}

.head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1200px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 1170px;
  }

  .range-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.range-input {
  width: 100%;
  margin: 20px 0;
  -webkit-appearance: none;
  background: transparent;
}

.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #7367f0;
  border-radius: 5px;
}

.range-input::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

.range-input:focus::-webkit-slider-runnable-track {
  background: #7367f0;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
}
}
</style>
